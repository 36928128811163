<template>
  <div class="row">
    <input-text
      v-model="filtro.codigo"
      class="col-6"
      :label="$t('modulos.acreditacao.filtro.codigo')"
      :placeholder="$t('modulos.acreditacao.filtro.codigo')"
      em-filtro
    />
    <input-text
      v-model="filtro.nomeParticipanteConta"
      class="col-6"
      :label="$t('modulos.acreditacao.filtro.nomeParticipanteConta')"
      :placeholder="$t('modulos.acreditacao.filtro.nomeParticipanteConta')"
      em-filtro
    />
  </div>
</template>
<script>
export default {
  props: {
    filtro: {},
  },
  data() {
    return { }

  },
};
</script>
